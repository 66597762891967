import AOS from "aos";
import "aos/dist/aos.css";
import DataTable from "datatables.net-dt";
import "datatables.net-responsive-dt";

jQuery(document).ready(function ($) {
    // import google api
    importMap();

    //aos settings global
    AOS.init({
        duration: 1200, // values from 0 to 3000, with step 50ms
        easing: "ease",
        offset: 150,
        // disable: 'mobile',
        disable: function () {
            var maxWidth = 1160;
            return window.innerWidth < maxWidth;
        },
    });

    //menu
    $("body").on("click", ".mobileMenu", function () {
        $("#page").toggleClass("mobileOpen");
        $(".menu .content").toggleClass("open");
        $(".menuContainer").toggleClass("active");
        $(".menuContainer .menu").toggleClass("show");

        $(this).toggleClass("open");
    });

    //close menu for desktop if resize is bigger than breakpoint
    $(window).on("resize", function () {
        var win = $(this); //this = window

        if (win.width() > 1160) {
            $("#page").removeClass("mobileOpen");
            $(".menu .content").removeClass("open");
            $(".menuContainer").removeClass("active");
            $(".menuContainer .menu").removeClass("show");

            $(".mobileMenu").removeClass("open");
        }
    });

    //conainer markings
    $("body").on("click", ".markingContainer .thumbnail", function (event) {
        event.preventDefault();
        $(".markingContainer .thumbnail").removeClass("active");
        $(".markingContainer .containerItem").removeClass("active");
        $(this).addClass("active");

        var id = $(this).attr("data-id");

        $(".markingContainer #" + id).addClass("active");
    });

    //menu detection to show sticky nav classes
    $(document).scroll(function () {
        if ($(window).scrollTop() !== 0) {
            $("header").addClass("scrolled");
        }

        if ($(window).scrollTop() === 0) {
            $("header").removeClass("scrolled");
        }
    });

    if (window.scrollY != 0) {
        $("header").addClass("scrolled");
    }

    //forms auto next
    $("form.bicCodes input[type=text]").keyup(function () {
        if (this.value.length == this.maxLength) {
            var $next = $(this).next("input[type=text]");
            if ($next.length) $(this).next("input[type=text]").focus();
            else $(this).blur();
        }
    });
    $("form#sizetypeform input[type=text]").keyup(function () {
        if (this.value.length == this.maxLength) {
            var $next = $(this).next("input[type=text]");
            if ($next.length) $(this).next("input[type=text]").focus();
            else $(this).blur();
        }
    });

    //header code form bootstrap mobile accordion

    var windowsize = $(window).width();

    if (windowsize < 992) {
        var mobileDataId = $("#bicCodesForm.bicCodes .accordion-button").attr(
            "data-bs-target-mobile"
        );
        $("#bicCodesForm.bicCodes .accordion-button").attr(
            "data-bs-target",
            mobileDataId
        );
        $("#bicCodesForm.bicCodes .accordion-button").addClass("collapsed");
        $("#bicCodesForm.bicCodes .accordion-collapse").removeClass("show");
        $("#bicCodesForm.bicCodes .accordion-button").attr(
            "aria-expanded",
            "true"
        );

        var mobileDataId = $(
            "#bicCodesForm.bicFacilityCodes .accordion-button"
        ).attr("data-bs-target-mobile");
        $("#bicCodesForm.bicFacilityCodes .accordion-button").attr(
            "data-bs-target",
            mobileDataId
        );
        $("#bicCodesForm.bicFacilityCodes .accordion-button").addClass(
            "collapsed"
        );
        $("#bicCodesForm.bicFacilityCodes .accordion-collapse").removeClass(
            "show"
        );
        $("#bicCodesForm.bicFacilityCodes .accordion-button").attr(
            "aria-expanded",
            "true"
        );

        var mobileDataId = $("#bicCodesForm.calculator .accordion-button").attr(
            "data-bs-target-mobile"
        );
        $("#bicCodesForm.calculator .accordion-button").attr(
            "data-bs-target",
            mobileDataId
        );
        $("#bicCodesForm.calculator .accordion-button").addClass("collapsed");
        $("#bicCodesForm.calculator .accordion-collapse").removeClass("show");
        $("#bicCodesForm.calculator .accordion-button").attr(
            "aria-expanded",
            "true"
        );
    }

    $(window).resize(function () {
        windowsize = $(window).width();

        if (windowsize < 992) {
            var mobileDataId = $(
                "#bicCodesForm.bicCodes .accordion-button"
            ).attr("data-bs-target-mobile");
            var DataId = $("#bicCodesForm.bicCodes .accordion-button").attr(
                "data-bs-target"
            );
            $("#bicCodesForm.bicCodes .accordion-button").addClass("collapsed");
            $("#bicCodesForm.bicCodes .accordion-collapse").removeClass("show");
            $("#bicCodesForm.bicCodes .accordion-button").attr(
                "aria-expanded",
                "true"
            );

            if (mobileDataId != DataId) {
                $("#bicCodesForm.bicCodes .accordion-button").attr(
                    "data-bs-target",
                    mobileDataId
                );
                $("#bicCodesForm.bicCodes .accordion-button").attr(
                    "aria-expanded",
                    "true"
                );
            }

            var mobileDataId = $(
                "#bicCodesForm.bicFacilityCodes .accordion-button"
            ).attr("data-bs-target-mobile");
            var DataId = $(
                "#bicCodesForm.bicFacilityCodes .accordion-button"
            ).attr("data-bs-target");
            $("#bicCodesForm.bicFacilityCodes .accordion-button").addClass(
                "collapsed"
            );
            $("#bicCodesForm.bicFacilityCodes .accordion-collapse").removeClass(
                "show"
            );
            $("#bicCodesForm.bicFacilityCodes .accordion-button").attr(
                "aria-expanded",
                "true"
            );

            if (mobileDataId != DataId) {
                $("#bicCodesForm.bicFacilityCodes .accordion-button").attr(
                    "data-bs-target",
                    mobileDataId
                );
                $("#bicCodesForm.bicFacilityCodes .accordion-button").attr(
                    "aria-expanded",
                    "true"
                );
            }

            var mobileDataId = $(
                "#bicCodesForm.calculator .accordion-button"
            ).attr("data-bs-target-mobile");
            var DataId = $("#bicCodesForm.calculator .accordion-button").attr(
                "data-bs-target"
            );
            $("#bicCodesForm.calculator .accordion-button").addClass(
                "collapsed"
            );
            $("#bicCodesForm.calculator .accordion-collapse").removeClass(
                "show"
            );
            $("#bicCodesForm.calculator .accordion-button").attr(
                "aria-expanded",
                "true"
            );

            if (mobileDataId != DataId) {
                $("#bicCodesForm.calculator .accordion-button").attr(
                    "data-bs-target",
                    mobileDataId
                );
                $("#bicCodesForm.calculator .accordion-button").attr(
                    "aria-expanded",
                    "true"
                );
            }
        }

        if (windowsize > 991) {
            var mobileDataId = $(
                "#bicCodesForm.bicCodes .accordion-button"
            ).attr("data-bs-target-mobile");
            var DataId = $("#bicCodesForm.bicCodes .accordion-button").attr(
                "data-bs-target"
            );
            $("#bicCodesForm.bicCodes .accordion-collapse").addClass("show");
            $("#bicCodesForm.bicCodes .accordion-button").removeClass(
                "collapsed"
            );
            $("#bicCodesForm.bicCodes .accordion-button").attr(
                "aria-expanded",
                "false"
            );

            if (mobileDataId == DataId) {
                $("#bicCodesForm.bicCodes .accordion-button").attr(
                    "data-bs-target",
                    DataId + "None"
                );
            }

            var mobileDataId = $(
                "#bicCodesForm.bicFacilityCodes .accordion-button"
            ).attr("data-bs-target-mobile");
            var DataId = $(
                "#bicCodesForm.bicFacilityCodes .accordion-button"
            ).attr("data-bs-target");
            $("#bicCodesForm.bicFacilityCodes .accordion-collapse").addClass(
                "show"
            );
            $("#bicCodesForm.bicFacilityCodes .accordion-button").removeClass(
                "collapsed"
            );
            $("#bicCodesForm.bicFacilityCodes .accordion-button").attr(
                "aria-expanded",
                "false"
            );

            if (mobileDataId == DataId) {
                $("#bicCodesForm.bicFacilityCodes .accordion-button").attr(
                    "data-bs-target",
                    DataId + "None"
                );
            }

            var mobileDataId = $(
                "#bicCodesForm.calculator .accordion-button"
            ).attr("data-bs-target-mobile");
            var DataId = $("#bicCodesForm.calculator .accordion-button").attr(
                "data-bs-target"
            );
            $("#bicCodesForm.calculator .accordion-collapse").addClass("show");
            $("#bicCodesForm.calculator .accordion-button").removeClass(
                "collapsed"
            );
            $("#bicCodesForm.calculator .accordion-button").attr(
                "aria-expanded",
                "false"
            );

            if (mobileDataId == DataId) {
                $("#bicCodesForm.calculator .accordion-button").attr(
                    "data-bs-target",
                    DataId + "None"
                );
            }
        }
    });

    // size lookup submission AJAX
    $("body").on("submit", "form#check-digit-form", function (e) {
        e.preventDefault();

        var code = "";
        var serialstart = $(
            "#check-digit-form .checkdigitserialinputstart"
        ).val();
        var serialend = $("#check-digit-form .checkdigitserialinputend").val();

        $("#check-digit-form .checkdigitbicselect").each(function (index) {
            var input = $(this);
            code += input.val();
        });

        $(".checkDigitError").html("");

        $.ajax({
            type: "post",
            url: site_data.adminajax,
            dataType: "json",
            data: {
                action: "digit_calculator",
                code: code,
                start: serialstart,
                limit: 9999,
                end: serialend,
            },
            success: function (response) {
                $("#checkDigitResults").show();

                if (response.error) {
                    $(".checkDigitError").html(
                        '<p class="error">' + response.error + "</p>"
                    );
                }

                //scrollto
                $("html, body").animate(
                    {
                        scrollTop: $("#checkDigitResults").offset().top - 175,
                    },
                    500
                );

                if (response.status == "success") {
                    $(".added").remove();
                    $("#csvInfo1").hide();
                    $("#csvInfo").hide();

                    var dataStart = "";
                    var dataEnd = "";

                    $.each(response.results, function (index, val) {
                        if (index === 100) {
                            return false; // breaks
                        }
                        var biccode = val.biccode;
                        var serial = val.serial;
                        var digit = val.digit;

                        $(
                            '<tr class="added"><td class="flexMobile align-items-center"><span class="hideOnDesktop tdHeading">Code:</span><span>' +
                                biccode +
                                '</span></td><td class="flexMobile align-items-center"><span class="hideOnDesktop tdHeading">Code:</span><span>' +
                                serial +
                                '</span></td><td class="flexMobile align-items-center"><span class="hideOnDesktop tdHeading">Code:</span><span>' +
                                digit +
                                "</span></td></tr>"
                        ).insertAfter(".ajaxDigitResponse .headings");

                        if (index == 0) {
                            dataStart = val.serial;
                        }
                        dataEnd = val.serial;
                    });

                    //create csv
                    let data = response.results;
                    const keys = Object.keys(data[0]);
                    const commaSeparatedString = [
                        keys.join(","),
                        data
                            .map((row) => keys.map((key) => row[key]).join(","))
                            .join("\n"),
                    ].join("\n");
                    const csvBlob = new Blob([commaSeparatedString]);
                    const a2 = document.getElementById("csv");
                    a2.href = URL.createObjectURL(csvBlob);

                    const a3 = document.getElementById("csv1");
                    a3.href = URL.createObjectURL(csvBlob);

                    $("#csvInfo").show();
                    $("#csvInfo1").show();

                    // $('.ajaxResponse').removeClass('error')
                    // $('.ajaxResponse').html('<table><tbody><th>BIC Code</th><th>Serial Number</th><th>Check Digit</th><tr><td><div><a href="/bic-codes/'+biccode+'/">'+biccode+'</a></div></td><td><div><p>'+serial+'</p></div></td><td><div><p>'+digit+'</p></div></td></tr></tbody></table>')
                    // $('.ajaxResponse').slideDown()
                }
            },
            error: function (response) {
                console.log(response);
            },
        });
    });

    //on country select submit form

    $("body").on("change", ".countrySearch", function (event) {
        event.preventDefault();
        console.log("hello");
        this.form.submit();
    });

    // size lookup submission AJAX
    $("body").on("submit", ".sizetypecode form", function (e) {
        e.preventDefault();

        var length = $(".sizetypecode form input#length").val();
        var height = $(".sizetypecode form input#height").val();
        var char1 = $(".sizetypecode form input#char1").val();
        var char2 = $(".sizetypecode form input#char2").val();

        if (length == "") {
            $(".sizetypecode form input#length").parent().addClass("error");
        } else {
            $(".sizetypecode form input#length").parent().removeClass("error");
        }

        if (height == "") {
            $(".sizetypecode form input#height").parent().addClass("error");
        } else {
            $(".sizetypecode form input#height").parent().removeClass("error");
        }

        if (char1 == "") {
            $(".sizetypecode form input#char1").parent().addClass("error");
        } else {
            $(".sizetypecode form input#char1").parent().removeClass("error");
        }

        if (char2 == "") {
            $(".sizetypecode form input#char2").parent().addClass("error");
        } else {
            $(".sizetypecode form input#char2").parent().removeClass("error");
        }

        if (length != "" && height != "" && char1 != "" && char2 != "") {
            $.ajax({
                type: "post",
                url: site_data.adminajax,
                dataType: "json",
                data: {
                    action: "get_size_type_info",
                    length: length,
                    height: height,
                    chars: char1 + char2,
                },
                success: function (response) {
                    $(".sizetypecode #lengthresult").html(response.length);
                    $(".sizetypecode #heightresult").html(response.height);
                    $(".sizetypecode #charsresult").html(response.chars);

                    if (response.warning == false) {
                        $(".sizetypecode .warning").hide();
                    } else {
                        $(".sizetypecode .warning").show();
                    }
                },
                error: function (response) {
                    console.log(response);
                },
            });
        } else {
            $(".sizetypecode #lengthresult").html("");
            $(".sizetypecode #heightresult").html("");
            $(".sizetypecode #charsresult").html("");
        }
    });

    //google map

    if ($("div#google-map").length) {
        initMap();
    }

    function importMap() {
        ((g) => {
            var h,
                a,
                k,
                p = " The Google Maps JavaScript API",
                c = "google",
                l = "importLibrary",
                q = "__ib__",
                m = document,
                b = window;
            b = b[c] || (b[c] = {});
            var d = b.maps || (b.maps = {}),
                r = new Set(),
                e = new URLSearchParams(),
                u = () =>
                    h ||
                    (h = new Promise(async (f, n) => {
                        await (a = m.createElement("script"));
                        e.set("libraries", [...r] + "");
                        for (k in g)
                            e.set(
                                k.replace(
                                    /[A-Z]/g,
                                    (t) => "_" + t[0].toLowerCase()
                                ),
                                g[k]
                            );
                        e.set("callback", c + ".maps." + q);
                        a.src =
                            `https://maps.${c}apis.com/maps/api/js?libraries=places&` +
                            e;
                        d[q] = f;
                        a.onerror = () =>
                            (h = n(Error(p + " could not load.")));
                        a.nonce = m.querySelector("script[nonce]")?.nonce || "";
                        m.head.append(a);
                    }));
            d[l]
                ? console.warn(p + " only loads once. Ignoring:", g)
                : (d[l] = (f, ...n) =>
                      r.add(f) && u().then(() => d[l](f, ...n)));
        })({
            key: site_data.google_api_key,
            v: "weekly",
            // Use the 'v' parameter to indicate the version to use (weekly, beta, alpha, etc.).
            // Add other bootstrap parameters as needed, using camel case.
        });
    }

    // function initMap() {
    //     var marks = $("#google-map .marker");
    //     var map = new google.maps.Map(document.getElementById("google-map"), {
    //         zoom: 16,
    //         maxZoom: 18,
    //     });
    //     var bounds = new google.maps.LatLngBounds();
    //     var polygons = [];

    //     marks.each(function () {
    //         var element = $(this);

    //         var geofence = element[0].dataset.geofence;

    //         if (geofence) {
    //             var coordinates = JSON.parse(geofence);

    //             console.log("coordinates", coordinates);

    //             var polygon = new google.maps.Polygon({
    //                 paths: coordinates,
    //                 strokeColor: "#DE465C",
    //                 strokeOpacity: 0.8,
    //                 strokeWeight: 2,
    //                 fillColor: "",
    //                 fillOpacity: 0,
    //             });

    //             polygon.setMap(map);
    //             polygons.push(polygon);

    //             // Adjust bounds to include the polygon
    //             // for (var i = 0; i < coordinates.length; i++) {
    //             //     bounds.extend(
    //             //         new google.maps.LatLng(
    //             //             coordinates[i][1],
    //             //             coordinates[i][0]
    //             //         )
    //             //     );
    //             // }
    //         }

    //         var latlng = new google.maps.LatLng(
    //             element.data("lat"),
    //             element.data("lng")
    //         );

    //         var icon = {
    //             url: "/wp-content/themes/tessellate/assets/images/marker.png",
    //             scaledSize: new google.maps.Size(30, 38),
    //         };

    //         var mark = new google.maps.Marker({
    //             position: latlng,
    //             icon: icon,
    //         });

    //         mark.setMap(map);
    //         bounds.extend(latlng);
    //     });

    //     map.setCenter(bounds.getCenter());
    //     map.fitBounds(bounds);
    // }
    function initMap() {
        google.maps.importLibrary("maps").then(function (MapModule) {
            var Map = MapModule.Map;

            google.maps.importLibrary("marker").then(function (MarkerModule) {
                var Marker = MarkerModule.Marker;

                // Your code using Map and Marker goes here
                var marks = $("#google-map .marker");
                var map = new google.maps.Map(
                    document.getElementById("google-map"),
                    {
                        zoom: 16,
                        maxZoom: 18,
                    }
                );
                var bounds = new google.maps.LatLngBounds();
                var polygons = [];

                marks.each(function () {
                    var element = $(this);

                    var geofence = element[0].dataset.geofence;

                    // Check if geofence data is available
                    if (geofence) {
                        var originalCoordinates = JSON.parse(geofence);

                        // Swap latitude and longitude for each coordinate
                        var coordinates = originalCoordinates.map(function (
                            coord
                        ) {
                            return { lat: coord[1], lng: coord[0] };
                        });

                        var polygon = new google.maps.Polygon({
                            paths: coordinates,
                            strokeColor: "#DE465C",
                            strokeOpacity: 0.8,
                            strokeWeight: 2,
                            fillColor: "",
                            fillOpacity: 0,
                        });

                        polygon.setMap(map);
                        polygons.push(polygon);

                        // Adjust bounds to include the polygon
                        for (var i = 0; i < coordinates.length; i++) {
                            bounds.extend(
                                new google.maps.LatLng(
                                    coordinates[i].lat,
                                    coordinates[i].lng
                                )
                            );
                        }
                    }

                    var latlng = new google.maps.LatLng(
                        element.data("lat"),
                        element.data("lng")
                    );

                    var icon = {
                        url: "/wp-content/themes/tessellate/assets/images/marker.png",
                        scaledSize: new google.maps.Size(30, 38),
                    };

                    var marker = new google.maps.Marker({
                        position: latlng,
                        icon: icon,
                    });

                    marker.setMap(map);
                    bounds.extend(latlng);
                });

                map.setCenter(bounds.getCenter());
                map.fitBounds(bounds);
            });
        });
    }

    autoPlayYouTubeModal();

    function autoPlayYouTubeModal() {
        var trigger = $(".videoModalTriger");
        trigger.click(function () {
            var theModal = $(this).data("bs-target");
            var videoSRC = $(this).attr("data-videoModal");

            var videoSRCauto =
                videoSRC +
                "?autoplay=1&mute=0&color=white&controls=0&modestbranding=1&playsinline=1&rel=0&enablejsapi=1";
            $(theModal + " iframe").attr(
                "allow",
                "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            );
            $(theModal + " iframe").attr("src", videoSRCauto);

            $(theModal).on("hidden.bs.modal", function (e) {
                $(theModal + " iframe").attr("src", "");
            });
        });
    }

    // Dan & Lewis - Temporary hack to change datatable column for BIC codes to 7 columns as provider is not needed
    if ($("#bic-datatable").length) {
        var type = $("#bic-datatable").data("type");
        if (type == "facility") {
            var columns = [
                { orderable: true },
                { orderable: true },
                { orderable: true },
                { orderable: true },
                { orderable: true },
                { orderable: true },
                { orderable: true },
                { orderable: true },
                { orderable: false },
            ];
        } else {
            var columns = [
                { orderable: true },
                { orderable: true },
                { orderable: true },
                { orderable: true },
                { orderable: true },
                { orderable: true },
                { orderable: false },
            ];
        }
    }

    // DATATABLES
    async function initializeDataTable() {
        let bicdatatable = new DataTable("#bic-datatable", {
            responsive: {
                details: {
                    display: DataTable.Responsive.display.childRowImmediate,
                    target: "",
                    type: "none",
                },
            },
            columnDefs: [
                { responsivePriority: 1, targets: 0 },
                { responsivePriority: 2, targets: 6 },
                { responsivePriority: 3, targets: 1 },
                { responsivePriority: 4, targets: 2 },
                { responsivePriority: 5, targets: 3 },
            ],
            // responsive: true,
            pageLength: 20,
            pagingType: "simple_numbers",
            language: {
                lengthMenu: "Results per page: _MENU_",
                info: "",
                paginate: {
                    previous:
                        '<svg xmlns="http://www.w3.org/2000/svg" width="22" height="26" viewBox="0 0 22 26"><text id="_" data-name="←" transform="translate(0 18)" fill="#093d4d" font-size="18" font-family="Navigo-Light, Navigo" font-weight="300"><tspan x="0" y="0">←</tspan></text></svg>',
                    next: '<svg xmlns="http://www.w3.org/2000/svg" width="22" height="26" viewBox="0 0 22 26"><text id="_" data-name="→" transform="translate(0 18)" fill="#093d4d" font-size="18" font-family="Navigo-Light, Navigo" font-weight="300"><tspan x="0" y="0">→</tspan></text></svg>',
                },
                infoFiltered: "",
            },
            oLanguage: {
                sSearch: "",
                searchPlaceholder: "Filter by keyword",
            },
            columns: columns,
            lengthMenu: [
                [10, 20, 50, 100, 200, 500, -1],
                [10, 20, 50, 100, 200, 500, "All"],
            ],

            drawCallback: function (settings) {
                $("#bicloader").css("display", "none");
                $("#bic-datatable").css("display", "table");
                $("html, body").animate(
                    { scrollTop: $("#bic-datatable_wrapper").offset().top },
                    "fast"
                );
            },
        });
    }

    // Call the function asynchronously
    initializeDataTable();
});
